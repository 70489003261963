import * as React from "react"


const Logo = () => (


<div style={{marginRight:'20px'}}><img style={ {width:'50px', marginRight:'20px'}} src={'https://i.postimg.cc/TwV4vMPd/camera.png'}>
  </img>
  </div>
)

export default Logo
